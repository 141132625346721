import { useContext } from 'react'
import { CompanyConfigurationContext } from 'providers/CompanyConfigurationProvider'

const useCompanyConfiguration = () => {
  const context = useContext(CompanyConfigurationContext)

  if (context === undefined) {
    throw new Error(
      'useCompanyConfiguration must be used within a CompanyConfigurationProvider'
    )
  }

  return context
}

export default useCompanyConfiguration
