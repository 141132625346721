import type {
  CompanyConfigurationActions,
  StateCompanyConfiguration,
} from 'interfaces'

export const INITIAL_STATE: StateCompanyConfiguration = {
  configuration: null,
  companyKey: null,
  isAuthenticated: false,
}

const CompanyConfigurationReducer = (
  companyConfiguration: StateCompanyConfiguration,
  action: CompanyConfigurationActions
): StateCompanyConfiguration => {
  switch (action.type) {
    case 'SAVE_CONFIGURATION':
      return {
        ...companyConfiguration,
        configuration: action.payload,
        companyKey: action.companyKey,
      }
    case 'SET_AUTHENTICATED':
      return {
        ...companyConfiguration,
        companyKey: action.companyKey,
        isAuthenticated: true,
      }
    default:
      throw new Error()
  }
}

export default CompanyConfigurationReducer
