import { useContext } from 'react'
import { DataDispatchContext } from 'providers/DataProvider'

const useDispatchData = () => {
  const context = useContext(DataDispatchContext)

  if (context === undefined) {
    throw new Error('useDispatchData must be used within a DataProvider')
  }

  return context
}

export default useDispatchData
