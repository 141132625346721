import useDispatchModal from 'hooks/useDispatchModal'
import { FormattedMessage, useIntl } from 'react-intl'
import { useTheme } from 'styled-components'
import Button from 'ui/components/Button'
import Container from 'ui/components/Container'
import Modal from 'ui/components/Modal'
import ArrowLeft from 'ui/icons/ArrowLeft'
import { Wrapper, Content, InnerContent } from './styles'

const companyAddress = (
  <p>
    Connecting-Jobs GmbH
    <br />
    Dorfstrasse 40A
    <br />
    CH-6319 Allenwinden
  </p>
)

const innerContentDe = (
  <InnerContent>
    <h1>Datenschutzbestimmungen</h1>
    <p>
      Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der
      EU-Datenschutzgrundverordnung (DSGVO), ist:
    </p>
    {companyAddress}

    <h2>Ihre Betroffenenrechte</h2>
    <p>
      Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können
      Sie jederzeit folgende Rechte ausüben:
    </p>
    <ul>
      <li>
        Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung
        (Art. 15 DSGVO),
      </li>
      <li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
      <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
      <li>
        Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund
        gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO),
      </li>
      <li>
        Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO)
        und
      </li>
      <li>
        Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt
        haben oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).
      </li>
    </ul>
    <p>
      Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit
      mit Wirkung für die Zukunft widerrufen. Sie können sich jederzeit mit
      einer Beschwerde an eine Aufsichtsbehörde wenden, z. B. an die zuständige
      Aufsichtsbehörde des Bundeslands Ihres Wohnsitzes oder an die für uns als
      verantwortliche Stelle zuständige Behörde. Eine Liste der
      Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden
      Sie unter:{' '}
      <a
        href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
        target="_blank"
        rel="noreferrer"
      >
        https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
      </a>
      .
    </p>
    <h3>Drittlandtransfer</h3>
    <p>Die erhobenen Daten werden ggfs. in folgende Drittländer übertragen:</p>
    <ul>
      <li>Schweiz</li>
      <li>Deutschland</li>
      <li>USA</li>
    </ul>
    <p>Folgende Datenschutzgarantien liegen vor:</p>
    <ul>
      <li>Angemessenheitsbeschluss EU-Kommission</li>
      <li>Standard-Vertragsklauseln</li>
      <li>Binding Corporate Rules</li>
    </ul>

    <h2>Check-In über diese App</h2>
    <h3>Art und Zweck der Verarbeitung</h3>
    <p>
      Für den Check-In über diese App benötigen wir einige personenbezogene
      Daten, die über eine Eingabemaske an uns übermittelt werden. Zum Zeitpunkt
      des Check-In werden zusätzlich folgende Daten erhoben:
    </p>
    <ul>
      <li>Zeitpunkt des Check-In</li>
      <li>Unternehmen für welches der Check-In gültig ist</li>
    </ul>
    <h3>Rechtsgrundlage</h3>
    <p>
      Die Verarbeitung der beim Check-In eingegebenen Daten erfolgt auf
      Grundlage einer Einwilligung des Nutzers (Art. 6 Abs. 1 lit. a DSGVO).
    </p>
    <h3>Empfänger</h3>
    <p>
      Empfänger der Daten sind ggf. technische Dienstleister, die für den
      Betrieb und die Wartung unserer App als Auftragsverarbeiter tätig werden.
      Dies sind folgende Unternehmen:
    </p>
    <ul>
      <li>Amazon Web Services, Inc.</li>
      <li>Formagrid, Inc.</li>
      <li>Florian Gyger Software</li>
    </ul>
    <h3>Drittlandtransfer</h3>
    <p>Die erhobenen Daten werden ggfs. in folgende Drittländer übertragen:</p>
    <ul>
      <li>Schweiz</li>
      <li>Deutschland</li>
      <li>USA</li>
    </ul>
    <p>Folgende Datenschutzgarantien liegen vor:</p>
    <ul>
      <li>Angemessenheitsbeschluss EU-Kommission</li>
      <li>Standard-Vertragsklauseln</li>
      <li>Binding Corporate Rules</li>
    </ul>
    <h3>Speicherdauer</h3>
    <p>
      Daten werden in diesem Zusammenhang nur verarbeitet, solange die
      entsprechende Einwilligung vorliegt.
    </p>
    <h3>Bereitstellung vorgeschrieben oder erforderlich</h3>
    <p>
      Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig,
      allein auf Basis Ihrer Einwilligung. Ohne die Bereitstellung Ihrer
      personenbezogenen Daten können wir Ihnen keinen Zugang auf unser Angebot
      gewähren.
    </p>

    <h2>SSL-Verschlüsselung</h2>
    <p>
      Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden
      wir dem aktuellen Stand der Technik entsprechende
      Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
    </p>

    <h2>Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO</h2>
    <h3>Einzelfallbezogenes Widerspruchsrecht</h3>
    <p>
      Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation
      ergeben, jederzeit gegen die Verarbeitung Sie betreffender
      personenbezogener Daten, die aufgrund Art. 6 Abs. 1 lit. f DSGVO
      (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt,
      Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmung
      gestütztes Profiling im Sinne von Art. 4 Nr. 4 DSGVO.
    </p>
    <p>
      Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht
      mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe
      für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und
      Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung,
      Ausübung oder Verteidigung von Rechtsansprüchen.
    </p>
    <h3>Empfänger eines Widerspruchs</h3>
    {companyAddress}

    <h2>Änderung unserer Datenschutzbestimmungen</h2>
    <p>
      Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie
      stets den aktuellen rechtlichen Anforderungen entspricht oder um
      Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B.
      bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die
      neue Datenschutzerklärung.
    </p>

    <h2>Fragen an den Datenschutzbeauftragten</h2>
    <p>
      Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail
      oder wenden Sie sich direkt an die für den Datenschutz verantwortliche
      Person in unserer Organisation: Die Datenschutzerklärung wurde mithilfe
      der activeMind AG erstellt, den Experten für{' '}
      <a
        href="https://www.activemind.de/datenschutz/datenschutzbeauftragter/"
        target="_blank"
        rel="noreferrer"
      >
        externe Datenschutzbeauftragte
      </a>{' '}
      (Version #2020-09-30).
    </p>
  </InnerContent>
)

const innerContentEn = (
  <InnerContent>
    <h1>Data protection regulations</h1>
    <p>
      The controller within the meaning of data protection laws, in particular
      the EU General Data Protection Regulation (GDPR), is:
    </p>
    {companyAddress}

    <h2>Your rights as a data subject</h2>
    <p>
      You can exercise the following rights at any time using the contact
      details provided for our data protection officer:
    </p>
    <ul>
      <li>
        Information about your data stored by us and its processing (Art. 15
        GDPR),
      </li>
      <li>Correction of incorrect personal data (Art. 16 GDPR),</li>
      <li>erasure of your data stored by us (Art. 17 GDPR),</li>
      <li>
        Restriction of data processing if we are not yet allowed to delete your
        data due to legal obligations (Art. 18 GDPR),
      </li>
      <li>Objection to the processing of your data by us (Art. 21 GDPR) and</li>
      <li>
        data portability if you have consented to data processing or have
        concluded a contract with us (Art. 20 GDPR).
      </li>
    </ul>
    <p>
      If you have given us your consent, you can revoke it at any time with
      effect for the future. You can lodge a complaint with a supervisory
      authority at any time, e.g. the competent supervisory authority in the
      federal state of your place of residence or the authority responsible for
      us as the controller. A list of supervisory authorities (for the
      non-public sector) with addresses can be found at:{' '}
      <a
        href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
        target="_blank"
        rel="noreferrer"
      >
        https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
      </a>
      .
    </p>
    <h3>Transfer to third countries</h3>
    <p>
      The data collected may be transferred to the following third countries:
    </p>
    <ul>
      <li>Switzerland</li>
      <li>Germany</li>
      <li>USA</li>
    </ul>
    <p>The following data protection guarantees are in place:</p>
    <ul>
      <li>EU Commission adequacy decision</li>
      <li>Standard contractual clauses</li>
      <li>Binding Corporate Rules</li>
    </ul>

    <h2>Check-in via this app</h2>
    <h3>Type and purpose of processing</h3>
    <p>
      For check-in via this app, we require some personal data that is
      transmitted to us via an input screen. The following data is also
      collected at the time of check-in:
    </p>
    <ul>
      <li>Time of the check-in</li>
      <li>Company for which the check-in is valid</li>
    </ul>
    <h3>Legal basis</h3>
    <p>
      The data entered during check-in is processed on the basis of the user's
      consent (Art. 6 para. 1 lit. a GDPR).
    </p>
    <h3>Recipients</h3>
    <p>
      Recipients of the data may be technical service providers who act as
      processors for the operation and maintenance of our app. These are the
      following companies:
    </p>
    <ul>
      <li>Amazon Web Services, Inc.</li>
      <li>Formagrid, Inc.</li>
      <li>Florian Gyger Software</li>
    </ul>
    <h3>Transfer to third countries</h3>
    <p>
      The data collected may be transferred to the following third countries:
    </p>
    <ul>
      <li>Switzerland</li>
      <li>Germany</li>
      <li>USA</li>
    </ul>
    <p>The following data protection guarantees are in place:</p>
    <ul>
      <li>EU Commission adequacy decision</li>
      <li>Standard contractual clauses</li>
      <li>Binding Corporate Rules</li>
    </ul>
    <h3>Storage duration</h3>
    <p>
      Data is only processed in this context as long as the corresponding
      consent has been given.
    </p>
    <h3>Provision prescribed or required</h3>
    <p>
      The provision of your personal data is voluntary, solely on the basis of
      your consent. Without the provision of your personal data, we cannot grant
      you access to our website.
    </p>

    <h2>SSL encryption</h2>
    <p>
      To protect the security of your data during transmission, we use
      state-of-the-art encryption methods (e.g. SSL) via HTTPS.
    </p>

    <h2>
      Information about your right to object in accordance with Art. 21 GDPR
    </h2>
    <h3>Right to object in individual cases</h3>
    <p>
      You have the right to object, on grounds relating to your particular
      situation, at any time to processing of personal data concerning you which
      is based on point (f) of Article 6(1) GDPR (data processing on the basis
      of a balancing of interests); this also applies to profiling based on this
      provision within the meaning of Article 4(4) GDPR.
    </p>
    <p>
      If you object, we will no longer process your personal data unless we can
      demonstrate compelling legitimate grounds for the processing which
      override your interests, rights and freedoms, or the processing serves the
      establishment, exercise or defense of legal claims.
    </p>
    <h3>Recipients of an objection</h3>
    {companyAddress}

    <h2>Changes to our privacy policy</h2>
    <p>
      We reserve the right to amend this privacy policy so that it always
      complies with current legal requirements or to implement changes to our
      services in the privacy policy, e.g. when introducing new services. The
      new privacy policy will then apply to your next visit.
    </p>

    <h2>Questions for the data protection officer</h2>
    <p>
      If you have any questions about data protection, please send us an e-mail
      or contact the person responsible for data protection in our organization
      directly: The data protection declaration was created with the help of
      activeMind AG, the experts for{' '}
      <a
        href="https://www.activemind.de/datenschutz/datenschutzbeauftragter/"
        target="_blank"
        rel="noreferrer"
      >
        external data protection officers
      </a>{' '}
      (version #2020-09-30).
    </p>
  </InnerContent>
)

const Privacy = () => {
  const dispatchModal = useDispatchModal()
  const theme = useTheme()
  const { locale } = useIntl()

  const getTranslatedInnerContent = () => {
    switch (locale) {
      case 'de':
        return innerContentDe
      case 'en':
        return innerContentEn
      default:
        console.error(
          `Could not find translation of data privacy for language: ${locale}`
        )
        return innerContentEn
    }
  }

  return (
    <Modal>
      <Wrapper>
        <Container supportPage>
          <Content>
            <Button
              type="button"
              onClick={() =>
                dispatchModal({
                  type: 'SET_MODAL',
                  isOpen: false,
                  modalType: 'PRIVACY',
                })
              }
              variant="ghost"
              iconPosition="left"
              size="medium"
              icon={
                <ArrowLeft
                  height={30}
                  width={28}
                  color={theme.colors.primary}
                />
              }
              margin={[0, 0, 62, 0]}
            >
              <FormattedMessage id="BACK" />
            </Button>
            {getTranslatedInnerContent()}
          </Content>
        </Container>
      </Wrapper>
    </Modal>
  )
}

export default Privacy
