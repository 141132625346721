import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;

  h1 {
    color: #000;
    font-family: 'Roboto', sans-serif;
    align-self: center;
  }
`
