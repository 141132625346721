import useDispatchModal from 'hooks/useDispatchModal'
import { FormattedMessage } from 'react-intl'
import { Wrapper } from './styles'

const SupportButton = () => {
  const dispatch = useDispatchModal()

  return (
    <Wrapper
      type="button"
      onClick={() =>
        dispatch({ type: 'SET_MODAL', isOpen: true, modalType: 'SUPPORT' })
      }
    >
      <FormattedMessage id="SUPPORT" />
    </Wrapper>
  )
}

export default SupportButton
