import React, { useReducer, createContext, Reducer } from 'react'
import DataReducer, { INITIAL_STATE } from 'reducers/DataReducer'
import type { DataActions, DispatchData, StateData } from 'interfaces'

type DataProviderProps = { children: React.ReactNode }

export const DataContext = createContext<StateData | undefined>(undefined)
export const DataDispatchContext = createContext<DispatchData | undefined>(
  undefined
)

const DataProvider = ({ children }: DataProviderProps) => {
  const [data, dispatchData] = useReducer<Reducer<StateData, DataActions>>(
    DataReducer,
    INITIAL_STATE
  )

  return (
    <DataContext.Provider value={data}>
      <DataDispatchContext.Provider value={dispatchData}>
        {children}
      </DataDispatchContext.Provider>
    </DataContext.Provider>
  )
}

export default DataProvider
