import * as Sentry from '@sentry/react'
import toast from 'react-hot-toast'
import { Auth } from 'aws-amplify'
import storage from 'helpers/storage'
import {
  DispatchCompanyConfiguration,
  DispatchData,
  DispatchModal,
} from 'interfaces'
import {
  fetchData,
  submitCheckIn as postCheckInData,
  verifyCompanyExists,
} from 'services'
import { DEFAULT_ERROR_MESSAGE } from 'config'
import { getErrorMessage } from 'helpers/getErrorMessage'

export const displayLoadingSpinner = (dispatch: DispatchModal) => {
  dispatch({ type: 'SET_MODAL', isOpen: true, modalType: 'LOADING' })
}

export const hideLoadingSpinner = (dispatch: DispatchModal) => {
  dispatch({ type: 'SET_MODAL', isOpen: false, modalType: 'LOADING' })
}

export const displayError = (error: string, dispatch: DispatchModal) => {
  dispatch({ type: 'SET_MODAL', isOpen: true, modalType: 'ERROR', error })
}

type LoginType = {
  companyKey: string
  password: string
  dispatchCompanyConfiguration: DispatchCompanyConfiguration
  dispatchModal: DispatchModal
}

export const login = async ({
  companyKey,
  password,
  dispatchCompanyConfiguration,
  dispatchModal,
}: LoginType) => {
  try {
    displayLoadingSpinner(dispatchModal)
    await Auth.signIn('customer', password)
    await verifyCompanyExists(companyKey)
    await reloadCompanyConfiguration({
      dispatchCompanyConfiguration,
      dispatchModal,
      companyKey,
    })
    dispatchCompanyConfiguration({
      type: 'SET_AUTHENTICATED',
      companyKey: companyKey,
      isAuthenticated: true,
    })
    storage.set('companyKey', companyKey)
  } finally {
    hideLoadingSpinner(dispatchModal)
  }
}

type SaveDataType = {
  companyKey: string
  dispatchCompanyConfiguration: DispatchCompanyConfiguration
  dispatchModal: DispatchModal
}

export const reloadCompanyConfiguration = async ({
  companyKey,
  dispatchCompanyConfiguration,
  dispatchModal,
}: SaveDataType) => {
  try {
    displayLoadingSpinner(dispatchModal)
    const data = await fetchData(companyKey)

    dispatchCompanyConfiguration({
      type: 'SAVE_CONFIGURATION',
      payload: data,
      companyKey,
    })
    dispatchCompanyConfiguration({
      type: 'SET_AUTHENTICATED',
      companyKey: companyKey,
      isAuthenticated: true,
    })
  } catch (err) {
    console.error(err)
    toast.error(getErrorMessage(err))
    Sentry.captureException(err)
    displayError(getErrorMessage(err), dispatchModal)
  } finally {
    hideLoadingSpinner(dispatchModal)
  }
}

type SubmitCheckInType = {
  companyKey: string
  firstName: string
  lastName: string
  email?: string
  phone?: string
  identificationDocuments?: string[]
  dispatch: DispatchData
}

export const submitCheckIn = async ({
  companyKey,
  firstName,
  lastName,
  email,
  phone,
  identificationDocuments,
  dispatch,
}: SubmitCheckInType) => {
  try {
    const data = await postCheckInData(
      companyKey,
      firstName,
      lastName,
      email,
      phone,
      identificationDocuments
    )

    const roomNumber = data ? data['roomNumber'] : null
    if (roomNumber) {
      dispatch({ type: 'SAVE_ROOM_NUMBER', payload: roomNumber })
    }
  } catch (err) {
    console.error(err)
    Sentry.captureException(err)
    toast.error((err as any)?.response?.data?.message || DEFAULT_ERROR_MESSAGE)
  }
}
