import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.background};
`

export const Content = styled.div`
  @media (min-width: ${({ theme }) => theme.screens.xl}) {
    padding: 200px 0 279px 0;
  }

  h1 {
    color: ${({ theme }) => theme.colors.font};
    font-size: 3rem;
    margin-bottom: 46px;
  }

  table {
    width: 100%;
    color: ${({ theme }) => theme.colors.font};
    font-size: 2.5rem;

    td {
      padding-bottom: 50px;
    }

    td.phone {
      min-width: 420px;
      text-align: right;
    }
  }
`
