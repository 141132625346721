import type { FC } from 'react'
import { ButtonProps } from 'ui/interfaces'
import { StyledButton } from './styles'

const Button: FC<ButtonProps> = ({ children, ...rest }) => (
  <StyledButton {...rest}>
    {rest.icon && rest.icon}
    <span>{children}</span>
  </StyledButton>
)

export default Button
