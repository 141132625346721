import React from 'react'
import { useTheme } from 'styled-components'
import { Grid, LargeSpinner } from './styles'

const Loading = () => {
  const theme = useTheme()

  return (
    <Grid>
      <LargeSpinner
        name="ball-scale-ripple"
        color={theme.colors.secondary}
        fadeIn="none"
      />
    </Grid>
  )
}

export default Loading
