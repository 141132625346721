const ArrowLeft = ({ color = '#3380BA', width = 27, height = 30 }) => (
  <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.275 14.73l21.45-12.46m-20.45 13l21.45 12.46"
      stroke={color}
      strokeWidth="4"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    />
  </svg>
)

export default ArrowLeft
