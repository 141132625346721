const theme = {
  screens: {
    sm: '480px',
    md: '768px',
    lg: '976px',
    xl: '1440px',
  },
  colors: {
    primary: '#3380ba',
    secondary: '#69bfff',
    font: '#fff',
    background: '#2a2a2a',
  },
  fontFamily: {
    // TODO: use the right font
    primary: ['Inter', 'sans-serif'],
  },
}

export default theme
