import useDispatchModal from 'hooks/useDispatchModal'
import useModal from 'hooks/useModal'
import { FormattedMessage } from 'react-intl'
import { useTheme } from 'styled-components'
import Button from 'ui/components/Button'
import Container from 'ui/components/Container'
import Modal from 'ui/components/Modal'
import ArrowLeft from 'ui/icons/ArrowLeft'
import { Wrapper, Content } from './styles'

const Iframe = () => {
  const data = useModal()
  const dispatchModal = useDispatchModal()
  const theme = useTheme()

  return (
    <Modal>
      <Wrapper>
        <Container supportPage>
          <Content>
            <Button
              type="button"
              onClick={() =>
                dispatchModal({
                  type: 'SET_MODAL',
                  isOpen: false,
                  modalType: 'IFRAME',
                })
              }
              variant="ghost"
              iconPosition="left"
              size="medium"
              icon={
                <ArrowLeft
                  height={30}
                  width={28}
                  color={theme.colors.primary}
                />
              }
              margin={[0, 0, 62, 0]}
            >
              <FormattedMessage id="BACK" />
            </Button>
            <iframe
              title="reservation"
              src={data.link}
              width="100%"
              height="700"
            />
          </Content>
        </Container>
      </Wrapper>
    </Modal>
  )
}

export default Iframe
