import React from 'react'
import ReactDOM from 'react-dom/client'
import { Amplify } from 'aws-amplify'
import App from 'routes/App'
import awsconfig from 'aws-exports'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { ENVIRONMENT, SENTRY_KEY } from 'config'

Sentry.init({
  dsn: SENTRY_KEY,
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: ENVIRONMENT,
})

Amplify.configure(awsconfig)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
