import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.colors.background};
`

export const Content = styled.div`
  align-self: center;
`

export const Title = styled.h1`
  margin-bottom: 32px;
  color: ${({ theme }) => theme.colors.font};
  font-size: 3rem;
`

export const Description = styled.h4`
  margin-bottom: 72px;
  color: ${({ theme }) => theme.colors.font};
  font-size: 2rem;
`

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
