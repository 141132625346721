import * as Sentry from '@sentry/react'
import { useEffect, useCallback } from 'react'
import { Auth } from 'aws-amplify'
import toast from 'react-hot-toast'
import storage from 'helpers/storage'
import type {
  DispatchCompanyConfiguration,
  DispatchModal,
  StateCompanyConfiguration,
} from 'interfaces'
import { hideLoadingSpinner, reloadCompanyConfiguration } from 'actions'
import getSearchParams from 'helpers/getSearchParams'
import { getErrorMessage } from 'helpers/getErrorMessage'

type UseFetchCompanyConfig = {
  companyConfiguration: StateCompanyConfiguration
  dispatchCompanyConfiguration: DispatchCompanyConfiguration
  dispatchModal: DispatchModal
}

const useFetchCompanyConfig = ({
  companyConfiguration,
  dispatchCompanyConfiguration,
  dispatchModal,
}: UseFetchCompanyConfig) => {
  const fetchCompanyConfig = useCallback(async () => {
    try {
      const companyKeyParam = getSearchParams('key')
      const companyKey = storage.get('companyKey')
      const user = await Auth.currentAuthenticatedUser()

      if (user && !companyKey && !companyKeyParam) {
        storage.remove('companyKey')
        hideLoadingSpinner(dispatchModal)
        return null
      } else if (user && companyKey && !companyKeyParam) {
        reloadCompanyConfiguration({
          companyKey,
          dispatchCompanyConfiguration,
          dispatchModal,
        })
        storage.set('companyKey', companyKey)
      } else if (user && !companyKey && companyKeyParam) {
        reloadCompanyConfiguration({
          companyKey: companyKeyParam,
          dispatchCompanyConfiguration,
          dispatchModal,
        })
        storage.set('companyKey', companyKeyParam)
      } else if (user && companyKey && companyKeyParam) {
        if (companyKey === companyKeyParam) {
          reloadCompanyConfiguration({
            companyKey: companyKeyParam,
            dispatchCompanyConfiguration,
            dispatchModal,
          })
          storage.set('companyKey', companyKeyParam)
        }
      }
    } catch (err) {
      console.error(err)
      if ('The user is not authenticated' !== err) {
        toast.error(getErrorMessage(err))
        Sentry.captureException(err)
      }
      storage.remove('companyKey')
      hideLoadingSpinner(dispatchModal)
    }
  }, [dispatchCompanyConfiguration, dispatchModal])

  useEffect(() => {
    if (!companyConfiguration.isAuthenticated) {
      fetchCompanyConfig()
    }
  }, [companyConfiguration.isAuthenticated, fetchCompanyConfig])
}

export default useFetchCompanyConfig
