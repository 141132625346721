import type { ModalActions, StateModal } from 'interfaces'

export const INITIAL_STATE: StateModal = {
  isOpen: false,
  modalType: 'CANCEL',
}

const ModalReducer = (_state: StateModal, action: ModalActions): StateModal => {
  switch (action.type) {
    case 'SET_MODAL':
      return {
        isOpen: action.isOpen,
        modalType: action.modalType,
        link: action.link,
        error: action.error,
      }
    default:
      throw new Error()
  }
}

export default ModalReducer
