import React, { useReducer, createContext, Reducer } from 'react'
import ModalReducer, { INITIAL_STATE } from 'reducers/ModalReducer'
import type { ModalActions, DispatchModal, StateModal } from 'interfaces'

type ModalProviderProps = { children: React.ReactNode }

export const ModalContext = createContext<StateModal | undefined>(undefined)
export const ModalDispatchContext = createContext<DispatchModal | undefined>(
  undefined
)

const ModalProvider = ({ children }: ModalProviderProps) => {
  const [modal, dispatchModal] = useReducer<Reducer<StateModal, ModalActions>>(
    ModalReducer,
    INITIAL_STATE
  )

  return (
    <ModalContext.Provider value={modal}>
      <ModalDispatchContext.Provider value={dispatchModal}>
        {children}
      </ModalDispatchContext.Provider>
    </ModalContext.Provider>
  )
}

export default ModalProvider
