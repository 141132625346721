import { useContext } from 'react'
import { ModalDispatchContext } from 'providers/ModalProvider'

const useDispatchModal = () => {
  const context = useContext(ModalDispatchContext)

  if (context === undefined) {
    throw new Error('useDispatchModal must be used within a ModalProvider')
  }

  return context
}

export default useDispatchModal
