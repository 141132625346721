import { lazy, Suspense } from 'react'
import { Toaster } from 'react-hot-toast'
import { ThemeProvider } from 'styled-components'
import renderModal from 'helpers/renderModal'
import GlobalStyle from 'ui/theme/global-style'
import theme from 'ui/theme/theme'
import Loading from 'ui/components/Loading'
import CompanyConfigurationProvider from 'providers/CompanyConfigurationProvider'
import ModalProvider from 'providers/ModalProvider'
import DataProvider from 'providers/DataProvider'
import useCompanyConfiguration from 'hooks/useCompanyConfiguration'
import useModal from 'hooks/useModal'
import { BrowserRouter } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import useData from 'hooks/useData'

import enMessages from 'i18n/en.json'
import deMessages from 'i18n/de.json'

const CheckInRoutes = lazy(() => import('./CheckInRoutes'))
const Login = lazy(() => import('modules/auth/Login'))

const messages = {
  de: deMessages as any,
  en: enMessages as any,
}

const Routes: React.FC<{}> = () => {
  const { configuration, isAuthenticated } = useCompanyConfiguration()
  const {
    data: { language },
  } = useData()
  const modal = useModal()

  const customCompanyTheme = (originalTheme: any) => ({
    ...originalTheme,
    colors: {
      ...originalTheme.colors,
      ...(configuration?.Primärfarbe && {
        primary: configuration?.Primärfarbe as string,
      }),
      ...(configuration?.Sekundärfarbe && {
        secondary: configuration?.Sekundärfarbe as string,
      }),
      ...(configuration?.Schriftfarbe && {
        font: configuration?.Schriftfarbe as string,
      }),
      ...(configuration?.Hintergrundfarbe && {
        background: configuration?.Hintergrundfarbe as string,
      }),
    },
  })

  return (
    <ThemeProvider theme={customCompanyTheme}>
      <GlobalStyle />
      <IntlProvider messages={messages[language]} locale={language}>
        <BrowserRouter>
          {modal.isOpen && renderModal(modal)}
          {isAuthenticated && configuration?.Firmenname ? (
            <Suspense fallback={<Loading />}>
              <CheckInRoutes />
            </Suspense>
          ) : (
            <Suspense fallback={<Loading />}>
              <Login />
            </Suspense>
          )}
        </BrowserRouter>
      </IntlProvider>
    </ThemeProvider>
  )
}

const App: React.FC<{}> = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <ModalProvider>
      <CompanyConfigurationProvider>
        <DataProvider>
          <Routes />
          <Toaster reverseOrder={false} />
        </DataProvider>
      </CompanyConfigurationProvider>
    </ModalProvider>
  </ThemeProvider>
)

export default App
