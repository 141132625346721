import useDispatchModal from 'hooks/useDispatchModal'
import { FormattedMessage } from 'react-intl'
import { Wrapper } from './styles'

const CancelButton = () => {
  const dispatch = useDispatchModal()

  return (
    <Wrapper
      type="button"
      onClick={() =>
        dispatch({ type: 'SET_MODAL', isOpen: true, modalType: 'CANCEL' })
      }
    >
      <FormattedMessage id="CANCEL_CHECK_IN" />
    </Wrapper>
  )
}

export default CancelButton
