import { Wrapper } from './styles'

const ErrorState = ({ error }: { error?: string }) => (
  <Wrapper>
    <h1>
      {error ||
        'Ein allgemeiner Fehler ist aufgetreten. Bitte wenden Sie sich an den Support.'}
    </h1>
  </Wrapper>
)

export default ErrorState
