import Button from 'ui/components/Button'
import { Title, Wrapper, Description, Flex, Content } from './styles'
import Modal from 'ui/components/Modal'
import useDispatchModal from 'hooks/useDispatchModal'
import useDispatchData from 'hooks/useDispatchData'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

const Cancel: React.FunctionComponent = () => {
  const dispatchModal = useDispatchModal()
  const dispatchData = useDispatchData()
  const navigate = useNavigate()

  const onCancelCheckIn = () => {
    dispatchData({ type: 'RESET_DATA' })
    dispatchModal({
      type: 'SET_MODAL',
      isOpen: false,
      modalType: 'CANCEL',
    })
    navigate('/')
  }

  const onBack = () =>
    dispatchModal({
      type: 'SET_MODAL',
      isOpen: false,
      modalType: 'CANCEL',
    })

  return (
    <Modal>
      <Wrapper>
        <Content>
          <Title>
            <FormattedMessage id="CANCEL_CHECK_IN" />
          </Title>
          <Description>
            <FormattedMessage id="CANCEL_CHECK_IN_CONFIRMATION" />
          </Description>
          <Flex>
            <Button
              type="button"
              onClick={onCancelCheckIn}
              variant="primary"
              size="medium"
              width={393}
            >
              <FormattedMessage id="CANCEL_CHECK_IN" />
            </Button>
            <Button
              type="button"
              onClick={onBack}
              variant="secondary"
              size="medium"
              width={411}
              margin={[0, 0, 0, 40]}
            >
              <FormattedMessage id="CANCEL_CHECK_IN_BACK" />
            </Button>
          </Flex>
        </Content>
      </Wrapper>
    </Modal>
  )
}

export default Cancel
