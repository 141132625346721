import { Element, Wrapper } from './styles'

interface ProgressBarProps {
  highlightedElements: number
  totalElements: number
}

const ProgressBar = ({
  highlightedElements,
  totalElements,
}: ProgressBarProps) => {
  const elements = []
  for (let i = 0; i < totalElements; i++) {
    const isHighlighted = i < highlightedElements

    elements.push(<Element key={i} isHighlighted={isHighlighted} />)
  }

  return <Wrapper>{elements}</Wrapper>
}

export default ProgressBar
