import type { FC, ReactNode } from 'react'
import styled from 'styled-components/macro'
import ProgressBar from 'ui/components/Progress'
import CancelButton from 'modules/DigitalReception/components/CancelButton'
import SupportButton from 'modules/DigitalReception/components/SupportButton'
import useCompanyConfiguration from 'hooks/useCompanyConfiguration'

type ContainerProps = {
  supportPage?: boolean
  step?: number
  progress?: boolean
  canCancel?: boolean
  children?: ReactNode
}

const Wrapper = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  background: ${({ theme }) => theme.colors.background};
  width: 90%;
`

const Container: FC<ContainerProps> = ({
  children,
  supportPage = false,
  step = 1,
  progress = false,
  canCancel,
}) => {
  const { configuration } = useCompanyConfiguration()

  const totalSteps = configuration?.Konfiguration.includes('Kopie ID / Pass')
    ? 4
    : 2

  return (
    <>
      {progress && (
        <ProgressBar totalElements={totalSteps} highlightedElements={step} />
      )}
      <Wrapper>
        {!supportPage && <SupportButton />}
        {children}
        {canCancel && <CancelButton />}
      </Wrapper>
    </>
  )
}

export default Container
