import { createGlobalStyle } from 'styled-components/macro'

const GlobalStyle = createGlobalStyle`
  html {
		font-family: 'Inter', 'Helvetica', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

    body {
      margin: 0;
			padding: 0;
			margin: 0;
    	background: ${({ theme }) => theme.colors.background};
		}

		:root {
			font-size: 16px;
		}
		
		h1, h2, h3, h4, h5, a, p, span {
			font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
			font-weight: 400;
			line-height: 1.2;
			margin: 0;
		}

		img {
			user-select: none;
		}

  	button {
			cursor: pointer;

			&:hover {
				outline: none;
			}
		}
	
		h2 {
			font-size: calc(24px + (36 - 24) * ((100vw - 300px) / (1600 - 300)));
		}
		h3 {
			font-size: calc(18px + (24 - 18) * ((100vw - 300px) / (1600 - 300)));
		}
		h4 {
			font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
		}
		h5 {
			font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
		}
		input {
			border-radius: 0;
		}

		button, input {
			-webkit-appearance: none;
		}

		button:focus {
			outline: none;
		}

    a {
      text-decoration: none;
    }
  }
`

export default GlobalStyle
