import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
`

export const Element = styled.div<{ isHighlighted: boolean }>`
  flex-grow: 1;
  background-color: ${({ theme, isHighlighted }) =>
    isHighlighted ? theme.colors.secondary : 'transparent'};
`
