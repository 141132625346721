import type { ReactNode } from 'react'
import styled from 'styled-components/macro'
import tinycolor from 'tinycolor2'
import colors from 'ui/theme/colors'

type ButtonProps = {
  wide?: boolean
  state?: 'danger' | 'warning' | 'success'
  variant: 'primary' | 'secondary' | 'ghost'
  size: 'large' | 'medium' | 'small'
  margin?: [number, number, number, number]
  href?: string
  withIcon?: boolean
  icon?: (HTMLElement & SVGElement) | ReactNode
  iconPosition?: 'left' | 'right'
  width?: number
}

export const StyledButton = styled.button<ButtonProps>`
  text-decoration: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 2.75rem;
  transition: 0.3s;
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
  padding: 1px 6px;

  ${({ margin }) =>
    margin &&
    `
		margin: ${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px;
	`}

  &:hover {
    transition: 0.3s;
  }

  ${({ wide }) =>
    wide &&
    `
      width: 100%;
      text-align: center;
	`}

  ${({ icon }) =>
    icon &&
    `
    display: flex;
    align-items: center;
  `}
	
  ${({ iconPosition }) =>
    iconPosition === 'right'
      ? `
			flex-direction: row-reverse;
      img, svg {
        margin-left: .5rem;
      }
    `
      : `
      img, svg {
        margin-right: .5rem;
      }
  `}

  ${({ variant, state, theme: { colors: themeColors } }) => {
    switch (variant) {
      case 'primary':
        return `
					color: ${tinycolor(themeColors.primary).isLight() ? '#000' : '#fff'};
					background: ${state ? colors.stateColors[state] : themeColors.primary};
					border: 2px solid transparent;
				`
      case 'secondary':
        return `
					color: ${state ? colors.stateColors[state] : themeColors.primary};
					background: transparent;
					border: 2px solid ${state ? colors.stateColors[state] : themeColors.primary};
				`
      case 'ghost':
        return `
					color: ${themeColors.primary};
					background: transparent;
					border: transparent;
				`
      default:
        return null
    }
  }}

	${({ size, width }) => {
    switch (size) {
      case 'large':
        return `
					width: ${`${width}px` || '466px'};
					height: 120px;
					text-align: center;
					line-height: 115px;
				`
      case 'medium':
        return `
					width: ${`${width}px` || '202px'};
					height: 60px;
					text-align: center;
					line-height: 55px;
					font-size: 2rem;
				`
      case 'small':
        return `
					padding: 17px 24px;
					font-size: 8pt;
				`
      default:
        return null
    }
  }}
	
	&:disabled {
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.background};
  }
`
