import React, { useReducer, createContext, Reducer } from 'react'
import CompanyConfigurationReducer, {
  INITIAL_STATE,
} from 'reducers/CompanyConfigurationReducer'
import type {
  CompanyConfigurationActions,
  DispatchCompanyConfiguration,
  StateCompanyConfiguration,
} from 'interfaces'
import useDispatchModal from 'hooks/useDispatchModal'
import useFetchCompanyConfig from 'hooks/useFetchCompanyConfig'

type CompanyConfigurationProviderProps = { children: React.ReactNode }

export const CompanyConfigurationContext = createContext<
  StateCompanyConfiguration | undefined
>(undefined)
export const CompanyConfigurationDispatchContext = createContext<
  DispatchCompanyConfiguration | undefined
>(undefined)

const CompanyConfigurationProvider = ({
  children,
}: CompanyConfigurationProviderProps) => {
  const [companyConfiguration, dispatchCompanyConfiguration] = useReducer<
    Reducer<StateCompanyConfiguration, CompanyConfigurationActions>
  >(CompanyConfigurationReducer, INITIAL_STATE)
  const dispatchModal = useDispatchModal()
  useFetchCompanyConfig({
    companyConfiguration,
    dispatchCompanyConfiguration,
    dispatchModal,
  })

  return (
    <CompanyConfigurationContext.Provider value={companyConfiguration}>
      <CompanyConfigurationDispatchContext.Provider
        value={dispatchCompanyConfiguration}
      >
        {children}
      </CompanyConfigurationDispatchContext.Provider>
    </CompanyConfigurationContext.Provider>
  )
}

export default CompanyConfigurationProvider
