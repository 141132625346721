import type { DataActions, StateData } from 'interfaces'

export const INITIAL_STATE: StateData = {
  data: {
    language: 'de',
  },
}

const DataReducer = (state: StateData, action: DataActions): StateData => {
  switch (action.type) {
    case 'SET_LANGUAGE':
      return {
        data: {
          ...state.data,
          language: action.payload,
        },
      }
    case 'SAVE_PERSONAL_DATA':
      return {
        data: {
          ...state.data,
          personal: action.payload,
        },
      }
    case 'SAVE_IDENTIFICATION':
      return {
        data: {
          ...state.data,
          identificationWay: action.payload,
        },
      }
    case 'SAVE_PASSPORT_PHOTO':
      return {
        data: {
          ...state.data,
          passportPhoto: action.payload,
        },
      }
    case 'SAVE_CARD_FRONT_PHOTO':
      return {
        data: {
          ...state.data,
          cardPhotoFront: action.payload,
        },
      }
    case 'SAVE_CARD_BACK_PHOTO':
      return {
        data: {
          ...state.data,
          cardPhotoBack: action.payload,
        },
      }
    case 'SAVE_ROOM_NUMBER':
      return {
        data: {
          ...state.data,
          roomNumber: action.payload,
        },
      }
    case 'RESET_DATA':
      return INITIAL_STATE
    default:
      throw new Error()
  }
}

export default DataReducer
