import styled from 'styled-components/macro'

export const Wrapper = styled.button`
  color: ${({ theme }) => theme.colors.font};
  text-decoration: underline;
  border: none;
  background: none;
  font-size: 1.75rem;
  position: absolute;
  right: 60px;
  bottom: 38px;
`
