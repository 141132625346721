import { API, Storage } from 'aws-amplify'

const apiName = 'api1518fb46'

export const verifyCompanyExists = async (companyKey: string) => {
  const path = `/companies/${companyKey}`
  await API.get(apiName, path, {})
}

export const fetchData = async (companyKey: string) => {
  const data = await API.get(apiName, `/companies/${companyKey}`, {})
  const taxiCompanies = await API.get(
    apiName,
    `/companies/${companyKey}/taxicompanies`,
    {}
  )
  return {
    ...data,
    Taxiunternehmen: taxiCompanies,
  }
}

export const submitCheckIn = async (
  companyKey: string,
  firstName: string,
  lastName: string,
  email?: string,
  phone?: string,
  identificationDocuments?: string[]
) => {
  const documentUrls: string[] = []
  const documentKeys: string[] = []

  if (identificationDocuments) {
    await Promise.all(
      identificationDocuments.map(async (document, index) => {
        const fetchResult = await fetch(document)
        const blob = await fetchResult.blob()
        const key = `${companyKey}-${index}.jpg`
        const result: any = await Storage.put(key, blob)
        const url = (await Storage.get(result.key)) as string
        documentUrls.push(url)
        documentKeys.push(key)
      })
    )
  }

  const path = `/companies/${companyKey}/customers`
  const result = await API.post(apiName, path, {
    body: {
      Vorname: firstName,
      Nachname: lastName,
      ...(email && { 'E-Mail': email }),
      ...(phone && { Telefon: phone }),
      'Kopie ID / Pass': documentUrls.map((url) => ({ url })),
    },
  })

  return result
}
