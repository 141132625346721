import type { ReactNode } from 'react'
import type { StateModal } from 'interfaces'
import Cancel from 'modules/DigitalReception/components/Cancel'
import Privacy from 'modules/DigitalReception/components/Privacy'
import Support from 'modules/DigitalReception/components/Support'
import Iframe from 'modules/DigitalReception/components/Iframe'
import Loading from 'ui/components/Loading'
import ErrorState from 'ui/components/ErrorState'
import Taxi from 'modules/DigitalReception/components/Taxi'

const renderModal = (modal: StateModal): ReactNode => {
  switch (modal.modalType) {
    case 'LOADING':
      return <Loading />
    case 'ERROR':
      return <ErrorState error={modal.error} />
    case 'CANCEL':
      return <Cancel />
    case 'SUPPORT':
      return <Support />
    case 'PRIVACY':
      return <Privacy />
    case 'IFRAME':
      return <Iframe />
    case 'TAXI':
      return <Taxi />
    default:
      return null
  }
}

export default renderModal
