import useCompanyConfiguration from 'hooks/useCompanyConfiguration'
import useDispatchModal from 'hooks/useDispatchModal'
import { FormattedMessage } from 'react-intl'
import { useTheme } from 'styled-components'
import Button from 'ui/components/Button'
import Container from 'ui/components/Container'
import Modal from 'ui/components/Modal'
import ArrowLeft from 'ui/icons/ArrowLeft'
import { Wrapper, Content } from './styles'

const Taxi = () => {
  const dispatchModal = useDispatchModal()
  const { configuration } = useCompanyConfiguration()
  const theme = useTheme()

  return (
    <Modal>
      <Wrapper>
        <Container supportPage>
          <Content>
            <Button
              type="button"
              onClick={() =>
                dispatchModal({
                  type: 'SET_MODAL',
                  isOpen: false,
                  modalType: 'TAXI',
                })
              }
              variant="ghost"
              iconPosition="left"
              size="medium"
              icon={
                <ArrowLeft
                  height={30}
                  width={28}
                  color={theme.colors.primary}
                />
              }
              margin={[0, 0, 62, 0]}
            >
              <FormattedMessage id="BACK" />
            </Button>
            <h1>
              <FormattedMessage id="TAXI_TITLE" />
            </h1>
            <table>
              {configuration?.Taxiunternehmen.map((t) => (
                <tr>
                  <td>{t.Name}</td>
                  <td className="phone">{t.Telefon}</td>
                </tr>
              ))}
            </table>
          </Content>
        </Container>
      </Wrapper>
    </Modal>
  )
}

export default Taxi
