import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.background};
`

export const Content = styled.div`
  @media (min-width: ${({ theme }) => theme.screens.xl}) {
    padding: 52px 0 0 0;
  }
`

export const InnerContent = styled.div`
  max-height: 900px;
  overflow-y: scroll;

  h1,
  h2,
  h3 {
    color: ${({ theme }) => theme.colors.font};
  }

  h1 {
    font-size: 3rem;
    margin-bottom: 46px;
  }

  h2 {
    font-size: 2.6rem;
    margin-bottom: 40px;
  }

  h3 {
    font-size: 2.3rem;
    margin-bottom: 34px;
  }

  p,
  ul {
    font-size: 2rem;
    color: ${({ theme }) => theme.colors.font};
    margin-bottom: 4rem;
    max-width: 998px;

    @media (max-width: ${({ theme }) => theme.screens.md}) {
      max-width: 100%;
    }
  }
`
