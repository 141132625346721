import Spinner from 'react-spinkit'
import styled from 'styled-components/macro'

export const Grid = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;

  div {
    align-self: center;
  }
`

export const LargeSpinner = styled(Spinner)`
  & > div {
    width: 140px;
    height: 140px;
  }
`
