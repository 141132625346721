import useCompanyConfiguration from 'hooks/useCompanyConfiguration'
import useDispatchModal from 'hooks/useDispatchModal'
import { FormattedMessage } from 'react-intl'
import { useTheme } from 'styled-components'
import Button from 'ui/components/Button'
import Container from 'ui/components/Container'
import Modal from 'ui/components/Modal'
import ArrowLeft from 'ui/icons/ArrowLeft'
import { Wrapper, Content } from './styles'

const Support = () => {
  const dispatchModal = useDispatchModal()
  const { configuration } = useCompanyConfiguration()
  const theme = useTheme()

  return (
    <Modal>
      <Wrapper>
        <Container supportPage>
          <Content>
            <Button
              type="button"
              onClick={() =>
                dispatchModal({
                  type: 'SET_MODAL',
                  isOpen: false,
                  modalType: 'SUPPORT',
                })
              }
              variant="ghost"
              iconPosition="left"
              size="medium"
              icon={
                <ArrowLeft
                  height={30}
                  width={28}
                  color={theme.colors.primary}
                />
              }
              margin={[0, 0, 62, 0]}
            >
              <FormattedMessage id="BACK" />
            </Button>
            <h1>
              <FormattedMessage id="SUPPORT" />
            </h1>
            <h4>
              <FormattedMessage id="SUPPORT_DESCRIPTION" />
            </h4>
            <p>
              <FormattedMessage id="SUPPORT_PHONE" />{' '}
              <b>{configuration!['Support-Telefon']}</b>
            </p>
          </Content>
        </Container>
      </Wrapper>
    </Modal>
  )
}

export default Support
